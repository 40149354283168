import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EmailAuthProvider, getAuth, linkWithCredential, User } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IRegistration } from '@app/modals/auth/registration/registration.interface';
import { ReportAnalyticsService } from '@app/services/report-analytics.service';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, catchError, from, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly displayName$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  displayName = toSignal(this.displayName$);

  logout$ = new Subject<void>();

  currentUser = signal<User | null>(null);

  user$ = this.afAuth.user;

  user = toSignal(this.user$, { initialValue: null });

  private readonly reportAnalytics = inject(ReportAnalyticsService);

  private readonly isLoading = signal(true);

  constructor(private readonly afAuth: AngularFireAuth, private readonly httpClient: HttpClient) {
    this.user$.subscribe((user) => {
      this.currentUser.set(user as User);
      if (user?.isAnonymous) {
        this.displayName$.next(null);
        Sentry.setUser({ id: user?.uid });
      }
      if (user?.displayName) {
        this.displayName$.next(user.displayName);
      }
    });

    this.afAuth.authState.subscribe((user) => {
      if (!user) {
        this.signInAnonymously();
      }

      this.isLoading.set(false);
    });
  }

  isLoggedIn$: Observable<boolean> = this.afAuth.user.pipe(map((u) => u?.isAnonymous === false));

  isLoggedIn = toSignal(this.isLoggedIn$);

  isAnonymous$: Observable<boolean> = this.afAuth.user.pipe(map((u) => u?.isAnonymous === true));

  isAnonymous = toSignal(this.isAnonymous$);

  getUserInfo() {
    return {
      customer_id: this.currentUser()?.uid,
      email: this.currentUser()?.email,
    };
  }

  public async deleteAccount() {
    const currentUser = await this.afAuth?.currentUser;
    if (!currentUser) {
      return;
    }

    await currentUser.delete();
    await this.afAuth.signOut();
    await this.signInAnonymously();
  }

  public signUp(data: IRegistration): Observable<any> {
    const anonymousUser = getAuth().currentUser;
    return from(
      anonymousUser?.isAnonymous
        ? linkWithCredential(anonymousUser, EmailAuthProvider.credential(data.email, data.password))
        : this.afAuth.createUserWithEmailAndPassword(data.email, data.password),
    ).pipe(
      switchMap(({ user }) => {
        if (user) {
          const displayName: string = data.fullName;
          // Sometimes firstName equal to null. Try need for catch this bug.
          try {
            if (!data.fullName) console.error('FirstName or LastName is equal to null', user);
          } catch (e) {
            console.log(e);
          }
          this.displayName$.next(displayName);
          Sentry.setUser({ id: user?.uid, email: user?.email!, username: displayName });
          this.reportAnalytics.sign_up(this.getUserInfo());

          return this.afAuth.user.pipe(
            filter((user) => user !== undefined),
            switchMap((user) => from(user!.updateProfile({ displayName }))),
            switchMap(() => from(user!.getIdToken(true))),
          );
        }
        return of(void 0);
      }),
      switchMap(() =>
        data.sendNewsLetter
          ? this.httpClient.post('/api/newsletter', {}).pipe(catchError(() => of(void 0)))
          : of(void 0),
      ),
    );
  }

  public async logout(): Promise<void> {
    await this.afAuth.signOut();
    await this.afAuth.signInAnonymously();
    this.logout$.next();
  }

  private async signInAnonymously() {
    return this.afAuth.signInAnonymously();
  }
}

export const useIsLoggedIn = () => inject(AuthService).isLoggedIn;

export const useIsAnonymous = () => inject(AuthService).isAnonymous;
